import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import {graphql } from 'gatsby'
import HomeIntro from "../components/home-intro/home-intro";
import HomeBook from "../components/home-book/home-book"
import FindHome from "../components/property-find/property-find"
import HomeFeedback from "../components/home-feedback/home-feedback"
import Content from '../components/area-guides/content'
import DetailMap from "../components/map/detail-map"
import PropertyRelated from "../components/property-related/featured-property";
import NotFoundPage from '../../src/pages/404';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';




const AreaGuidesDetail = props => {
  const GQLPage = props.data.glstrapi?.areaGuide
  if(GQLPage.Banner_Image) {
  return (
    <Layout bannerimage={GQLPage.Banner_Image} pageclass="areaguides-detail">
      <div className="areaguides-detail-wrapper">
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Name}
          description={GQLPage.Meta_Description}
        />
        <HomeIntro
          layout={GQLPage.Layout}
          title={GQLPage.Banner_Title}
          content={`<p> ${GQLPage.Banner_Description} </p>`}
          ctas={GQLPage.Banner_CTA}
          // reviews={GQLPage.Banner_Reviews}
          reviews="true"
          bannerimage={GQLPage.Banner_Image}
          imagetransforms={GQLPage.imagetransforms}
          id={GQLPage.id}
          imagename="area-guides.Banner_Image.bannerimg"
          baselabel="Areas We Cover" baseurl="/about/areas-we-cover/" menulabel={GQLPage.Name}
        />

        <Content
          above_content={GQLPage.Above_Content}
          below_content={GQLPage.Below_Content}
          image={GQLPage.Video_Tile.Video_Tile_Image.url}
          videourl={GQLPage.Video_Tile.Video_URL}
          // isOpen={isOpen}
          // setIsOpen={setIsOpen}
          // propertyDetailsLink={`${AreaguidesURL}/${item.URL}`}
        />

{GQLPage.Latitude && GQLPage.Longitude && (
        <DetailMap
          Latitude={GQLPage.Latitude}
          Longitude={GQLPage.Longitude}
        />
      )}
        <FindHome />
        <PropertyRelated title={`Featured Properties in ${GQLPage.Name}`} fromAreaguides areaName={GQLPage.Name}/>
        <HomeFeedback title={GQLPage.Title} />
        <HomeBook title={GQLPage.Title} />
      </div>
    </Layout>
  )
} else {
  return (
<empty></empty>

  )
}
}

export default AreaGuidesDetail

export const areaguideDetailQuery = graphql`
query AreaguideQuery($id: ID!) {
  glstrapi {
    areaGuide(id: $id) {
      Above_Content
      Banner_CTA {
        Banner_CTA_Label
        Banner_CTA_Link {
          Primary_URL
          Secondary_URL
          Label
        }
        Banner_CTA_Secondary_Link
      }
      Banner_Description
      Banner_Image {
        url
      }
      Banner_Title
      Below_Content
      Latitude
      Longitude
      Meta_Description
      Meta_Title
      Modules {
        ... on GLSTRAPI_ComponentComponentsModules {
          id
          Select_Module
        }
      }
      Name
      Tile_Image {
        url
        alternativeText
      }
      Tile_Intro
      URL
      Video_Tile {
        Video_URL
        Video_Tile_Image {
          url
          alternativeText
        }
      }
      Video_URL
      id
    }
  }
}`