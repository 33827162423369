/**
 * External dependencies
 */
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import HTMLReactParser from "html-react-parser"

/**
 * Internal dependencies
 */
import BtnLink from "../btn-link/btn-link"
import IconCircle from "../icon-circle/icon-circle"
// import usePropertyDetails from "./use-property-details"
import VideoPlaceholder from "../video-placeholder/video-placeholder"
import VideoItem from "../video-item/video-item"
import {PropertValuationURL} from "../utils"

import "./styles/_index.scss"

const Content = props => {
  //  const items = usePropertyDetails()
  //  const videoImage = items[0].node.childrenImageSharp[0].fluid.src
   const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="areaguide-details-wrapper short-intro-wrapper">
        <div
          className="areaguide-details"
          data-aos="fade-up"
          data-item="description"
        >
          <div className="shell">
            <Row className="justify-content-md-space-between">
              <Col lg="7">
                <div id="description" className="areaguide-details__content">
                  {props?.above_content && <>{HTMLReactParser(props?.above_content)}</>}
                  <VideoItem
                      image={props.image}
                      videourl={props.videourl}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      // propertyDetailsLink={`${AreaguidesURL}/${item.URL}`}
                    >
                  
                    </VideoItem>
                  {props?.below_content && <>{HTMLReactParser(props?.below_content)}</>}
                </div>
              </Col>
              <Col
                lg={{
                  span: 4,
                  offset: 1,
                }}
              >
                <div className="areaguide-details__sticky">
                  <h4>Get in touch</h4>
                  <ul>
                    <li>
                      <Row className="align-items-md-center">
                        <Col md="auto">
                          <IconCircle color="#EAF2F4">
                            <i className="icon-pin"></i>
                          </IconCircle>
                        </Col>
                        <Col>
                          {
                            "LambornHill, 19-21 West Street, Sittingbourne, Kent, ME10 1AJ"
                          }
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row className="align-items-md-center">
                        <Col md="auto">
                          <IconCircle color="#EAF2F4">
                            <i className="icon-phone-bold"></i>
                          </IconCircle>
                        </Col>
                        <Col>
                          <a href={`tel: +${"01795 293000"}`}>
                            {"01795 293000"}
                          </a>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row className="align-items-md-center">
                        <Col md="auto">
                          <IconCircle color="#EAF2F4">
                            <i className="icon-envelope"></i>
                          </IconCircle>
                        </Col>
                        <Col>
                          <Link to="/contact-estate-agents-in-sittingbourne/">
                            sittingbourne@lambornhill.com
                          </Link>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row className="align-items-md-center">
                        <Col md="auto">
                          <IconCircle color="#EAF2F4">
                            <i className="icon-price"></i>
                          </IconCircle>
                        </Col>
                        <Col>
                          Do you need to sell your home?{" "}
                          <div>
                            <strong>
                              <Link to={PropertValuationURL}>Book a valuation</Link>
                            </strong>{" "}
                            with one of our team.
                          </div>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default Content
