/**
 * External dependencies
 */
import React from "react"
import { Link } from "gatsby"

/**
 * Internal dependencies
 */
import VideoItem from "../video-item/video-item"
import IconCircle from "../icon-circle/icon-circle"
import { Col, Row } from "react-bootstrap"

const PropertyBox = (props, {
  setIsOpen,
  isOpen,
}) => {

  // property details url structure
  let uriStr = "";
  if (props?.data?.search_type === "sales" && (props?.data?.department === 'residential') || (props?.data?.department === 'new_developments') ) {
    uriStr = `property-for-sale/`
  } else if (props?.data?.search_type === "lettings" && props?.data?.department === 'residential') {
    uriStr = `property-to-rent/`
  }
  // property details url structure

  return (
    <div className="property-box">
      <VideoItem
        video="NpEaa2P7qZI"
        image={props?.data?.images[0]?.url}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        propertyDetailsLink={`/${uriStr}${props?.data?.slug}-${props?.data?.id}/`}
      >
        <Link to={`/${uriStr}${props?.data?.slug}-${props?.data?.id}/`}>
          {/* <h5>Offers Over</h5> */}
          {
            props?.data?.price &&
            <h3>£ {props?.data?.price.toLocaleString()} {props?.data?.search_type === "lettings" ? "PCM" : ""}</h3>
          }

          {
            props?.data?.display_address &&
            <p>{props?.data?.display_address}</p>
          }
          
          <Row>
            {
              props?.data?.bedroom > 0 &&
              props?.data?.bedroom &&
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col>
                    <IconCircle color="#EAF2F4">
                      <i className="icon-bed"></i>
                    </IconCircle>
                  </Col>
                  <Col>{props?.data?.bedroom}</Col>
                </Row>
              </Col>
            }

            {
              props?.data?.bathroom > 0 &&
              props?.data?.bathroom &&
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col>
                    <IconCircle color="#EAF2F4">
                      <i className="icon-bathroom"></i>
                    </IconCircle>
                  </Col>
                  <Col>{props?.data?.bathroom}</Col>
                </Row>
              </Col>
            }

            {
              props?.data?.reception > 0 &&
              props?.data?.reception &&
              <Col xs="auto">
                <Row className="align-items-center">
                  <Col>
                    <IconCircle color="#EAF2F4">
                      <i className="icon-chair"></i>
                    </IconCircle>
                  </Col>
                  <Col>{props?.data?.reception}</Col>
                </Row>
              </Col>
            }            
          </Row>
        </Link>
      </VideoItem>
    </div>
  )
}

export default PropertyBox
